<template>
  <b-card>
    <b-row class="blog-list-wrapper mt-1">
      <b-col
        v-for="data in optionsLocal.permission"
        :key="data.id"
        md="12"
      >
        <b-card-text><h4>{{data.name}}</h4></b-card-text>
        <div class="blog-list-wrapper mb-2">
          <span
            v-for="item in data.item"
            :key="item.id"
            md="5"
          >
            <b-button
              v-if="item.active"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-1 mr-1"
              @click="hapusPermission(data.guard_name, item.guard_name, item.name)"
            >
              <feather-icon icon="CheckIcon" />
              {{item.guard_name}}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1 mr-1"
              @click="pilihPermission(data.guard_name, item.guard_name, item.name)"
            >
              {{item.guard_name}}
            </b-button>
          </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/connection-api/rules/storeModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      roleId: null,
      roleName: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'rules'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.roleId = this.$route.params.id
      this.reloadData()
    }
  },
  methods: {
    reloadData() {
      const USER_APP_STORE_MODULE_NAME = 'rules'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

      store.dispatch('rules/fetchDataPermission', { id: this.roleId })
        .then(response => {
          this.optionsLocal = response.data.data
          this.roleName = response.data.data.guard_name
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.optionsLocal = {}
          }
        })
    },
    pilihPermission(id, guardName, name) {
      const localdata = {
        role_id: this.roleName,
        v1: guardName,
        v2: name,
      }
      store.dispatch('rules/addDataPermission', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
    hapusPermission(id, guardName, name) {
      const localdata = {
        role_id: this.roleName,
        v1: guardName,
        v2: name,
      }
      store.dispatch('rules/removeDataPermission', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
